export const getNumber = (num) => {
  let str = "" + num;
  let pad = "00000";
  let ans = pad.substring(0, pad.length - str.length) + str;
  return ans;
};

export const transformColumnName = (name) => {
  return name
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};


export const formatValue = (value) => {
  if (typeof value !== "number") return value; // Return as is if not a number
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const generateHourLabels = () => {
  return Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0");
    return `${hour}:00`;
  });
};

export const renderCellValue = (value) => {
  if (value === undefined || value === null) {
    return ''; // Handle undefined or null values
  }
  if (typeof value === 'object') {
    if (value.first && value.last) {
      // Handle objects with 'first' and 'last' properties
      return `${value.first} ${value.last}`;
    }
    // Handle other objects (e.g., convert to string)
    return Object.keys(value).map(key => `${key}: ${value[key]}`).join(', ');
  }
  return value;
};

export function numberWithCommas(x) {
  if (x === null || x === undefined) {
    return "";
  }
  return x.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function formatNumber(value) {
  // Check if the value is a number
  if (typeof value === 'number' && !isNaN(value)) {
      // Format the number to two decimal places and add commas
      return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (value instanceof Date) {
      // Format the date as desired
      return value.toLocaleDateString('en-US'); // Example format, adjust as needed
  } else {
      // Handle other cases (e.g., return a placeholder or the original value)
      return value;
  }
}

export const getSkuNumber = (product) => {
  let value;
  product.sort((a, b) => {
    return a.sku - b.sku;
  });
  if (product?.length == 0) {
    value = (0 + 1).toString().padStart(4, "0");
  } else {
    for (let i = 1; i <= product.length; i++) {
      if (product[i - 1].sku !== i) {
        value = i.toString().padStart(4, "0");
        break;
      } else {
        value = (i + 1).toString().padStart(4, "0");
      }
    }
  }
  return value;
};

export function incrementCharacter(string) {
  function getValue(s) {
    return s?.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1;
  }
  function setValue(n) {
    var result = "";
    do {
      result = ((n % 26) + 10).toString(36) + result;
      n = Math.floor(n / 26) - 1;
    } while (n >= 0);
    return result.toUpperCase();
  }

  return setValue(getValue(string) + 1);
}
