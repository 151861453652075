import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { i18n } from "../../../../app/pages/private/languageSelector";
import { getSubscription } from "../../../../_redux/subscription/subscriptionService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PricingPlanDialog from "../../../../app/pages/company/company-edit-Dialog/PricingPlanDialog";
import ErrorIcon from "@material-ui/icons/Error";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);

  const { subscriptionData } = useSelector((state) => state.subscription);

  const trialEndedAtFormatted = moment(
    subscriptionData?.subscription?.trialEndedAt
  ).fromNow();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSubscription());
    };
    fetchData();
  }, [dispatch]);

  const trialEndedAt = subscriptionData?.subscription?.trialEndedAt;
  const trialEndDays = trialEndedAt
    ? moment(trialEndedAt)
        .startOf("day")
        .diff(moment().startOf("day"), "days")
    : null;

  return (
    <>
      <div
        className={`footer py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
        id="kt_footer"
        style={{
          backgroundColor:
            subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
            subscriptionData?.subscription?.currentPlan?.status === "Running"
              ? "#FFF6DC"
              : subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
                subscriptionData?.subscription?.currentPlan?.status ===
                  "Expired"
              ? "#EB57571A"
              : "white",
          direction: language === "arabic" ? "rtl" : "ltr",
        }}
      >
        <div
          className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">
              {today.toString()}
            </span>{" "}
            &copy;{" "}
            <a
              href="/#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark-75 text-hover-primary"
            >
              {i18n("LandingPage.Fatoraty")}
            </a>
          </div>
          {subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
            subscriptionData?.subscription?.currentPlan?.status ===
              "Running" && (
              <div className="text-dark order-2 order-md-1">
                <span className="text-dark-75 text-hover-primary">
                  {i18n(
                    "SubscriptionModals.TheFreeTrialForUsersManagementExpiresIn"
                  )}{" "}
                  {trialEndDays} {i18n("SubscriptionModals.days")}
                </span>
              </div>
            )}
          {subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
            subscriptionData?.subscription?.currentPlan?.status ===
              "Expired" && (
              <div className="text-dark order-2 order-md-1">
                <span style={{ color: "#EB5757" }}>
                  {i18n(
                    "SubscriptionModals.YourUsersManagementSubscriptionHasExpired"
                  )}{" "}
                  {trialEndedAtFormatted} .
                  {i18n(
                    "SubscriptionModals.PleaseActivateTheSubscriptionToContinueUsingThisService"
                  )}
                </span>
              </div>
            )}
          {subscriptionData?.subscription?.currentPlan?.status === "Paused" && (
            <p className="mt-2 d-flex justify-content-center text-dark order-2 order-md-1">
              <span className="mr-1">
                <ErrorIcon color="error" />
              </span>
              <span className="text-danger">
                {i18n(
                  "SubscriptionModals.SubscriptionAutoRenewalFailedEnsurePayamentCardHasSufficientFunds"
                )}
              </span>
            </p>
          )}
          <div className="nav nav-dark order-1 order-md-2">
            {subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
              subscriptionData?.subscription?.currentPlan?.status !==
                "Paused" && (
                <a
                  href="/#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link pr-3 pl-0"
                >
                  {i18n("LandingPage.About")}
                </a>
              )}
            {subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
              (subscriptionData?.subscription?.currentPlan?.status ===
                "Expired" ||
                subscriptionData?.subscription?.currentPlan?.status ===
                  "Running") && (
                <span
                  className="try-for-free"
                  onClick={() => setShowPricingPlanModal(true)}
                >
                  {i18n("global.SUBSCRIBE")}
                </span>
              )}
            {subscriptionData?.subscription?.currentPlan?.status ===
              "Paused" && (
              <span className="try-for-free">
                {i18n("SubscriptionModals.RETRYPAYMENT")}
              </span>
            )}
          </div>
        </div>
      </div>
      <PricingPlanDialog
        showModal={showPricingPlanModal}
        onHide={() => setShowPricingPlanModal(false)}
      />
    </>
  );
}
