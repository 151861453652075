/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import IconDashboard from "../../../../../assets/img/icon-dashboard.svg";
import IconCustomers from "../../../../../assets/img/icon-customers.svg";
import IconInvoices from "../../../../../assets/img/icon-invoices.svg";
import IconProducts from "../../../../../assets/img/icon-products.svg";
import IconCompany from "../../../../../assets/img/icon-company.svg";
import BarIcon from "../../../../../assets/img/bar-icon.svg";
import { i18n } from "../../../../../app/pages/private/languageSelector";
import { shallowEqual, useSelector } from "react-redux";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAside } from "../../AsideContext";
import { Popover, PopoverBody } from "reactstrap";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const {
    setPopoverOpen,
    isAsideOpen,
    closeCompanyMenuList,
    openCompanyMenuList,
    togglePopover,
    popoverOpen,
  } = useAside();
  const [rolePayload, setRolePayload] = useState([]);
  const [openCompanyMenu, setOpenCompanyMenu] = useState(false);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const [analyticsPopoverOpen, setAnalyticsPopoverOpen] = useState(false);
  const [companyPopoverOpen, setCompanyPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const { role } = useSelector(
    (state) => ({
      role: state.auth.user?.rolePayload,
    }),
    shallowEqual
  );
  const { user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.auth);

  const { subscriptionData } = useSelector((state) => state.subscription);

  const isTrialPlan =
    subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
    subscriptionData?.subscription?.userTrial?.isTrialStarted === true;

  const isTrialExpired =
    isTrialPlan &&
    subscriptionData?.subscription?.currentPlan?.status === "Expired" &&
    subscriptionData?.subscription?.userTrial?.isTrialExpired === true;

  const isSubscriptionPaused =
    subscriptionData?.subscription?.currentPlan?.status === "Paused";

  useEffect(() => {
    if (role && role.backOfficePayload) {
      setRolePayload([...Object.values(role.backOfficePayload)]);
    }
  }, [role]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
        setAnalyticsPopoverOpen(false); // Close analytics popover on outside click
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverRef]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? `${!hasSubmenu && "menu-item-active"} menu-item-open`
      : "";
  };

  const handleCompanyMenuClick = () => {
    setCompanyPopoverOpen(true);
    if (isAsideOpen === false) {
      closeCompanyMenuList();
      togglePopover();
      setIsAnalyticsOpen(false);
      setAnalyticsPopoverOpen(false);
    } else {
      setOpenCompanyMenu((prev) => !prev);
      setIsAnalyticsOpen(false);
    }
  };
  const toggleAnalyticsMenu = () => {
    setCompanyPopoverOpen(false);
    if (isAsideOpen) {
      setIsAnalyticsOpen((prev) => !prev);
      setOpenCompanyMenu(false);
    } else {
      setAnalyticsPopoverOpen((prev) => !prev);
    }
  };

  const unSubscribeSubscription =
    subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
    subscriptionData?.subscription?.currentPlan?.status === "Running" &&
    subscriptionData?.subscription?.subscriptionInfo?.isUnsubscribed;

  return (
    <>
      <ul
        className={`menu-nav ${layoutProps.ulClasses}`}
        style={{ direction: language === "arabic" ? "rtl" : "ltr" }}
      >
        {rolePayload[0]?.selected && (
          <li
            style={{
              pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
              opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
            }}
            onClick={() => setPopoverOpen(false)}
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="menu-icon">
                <img
                  src={IconDashboard}
                  alt="#"
                  title="Dashboard"
                  width="20px"
                />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.DASHBOARD")}
              </span>
            </NavLink>
          </li>
        )}
        {rolePayload[1]?.selected && (
          <li
            style={{
              pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
              opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
            }}
            onClick={() => setPopoverOpen(false)}
            className={`menu-item ${getMenuItemActive("/customers", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/customers">
              <span className="menu-icon">
                <img
                  src={IconCustomers}
                  alt="#"
                  title="Customer"
                  width="20px"
                />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.CUSTOMER")}
              </span>
            </NavLink>
          </li>
        )}
        {rolePayload[2]?.selected && (
          <li
            style={{
              pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
              opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
            }}
            onClick={() => setPopoverOpen(false)}
            className={`menu-item ${getMenuItemActive("/invoices", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/invoices">
              <span className="menu-icon">
                <img src={IconInvoices} alt="#" title="Invoice" width="20px" />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.INVOICES")}
              </span>
            </NavLink>
          </li>
        )}
        {rolePayload[5]?.selected && (
          <li
            className={`menu-item menu-item-submenu ${
              isAnalyticsOpen ? "menu-item-open" : ""
            }`}
            aria-haspopup="true"
          >
            <div
              style={{
                pointerEvents:
                  (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-link analytics-hover ${
                isAnalyticsOpen ? "bg-primary text-light" : ""
              }`}
              onClick={toggleAnalyticsMenu}
              id="AnalyticsPopover"
            >
              <span className="menu-icon">
                {isAnalyticsOpen ? (
                  <img src={BarIcon} alt="#" title="Analytics" width="20px" />
                ) : (
                  <img src={BarIcon} alt="#" title="Analytics" width="20px" />
                )}
              </span>
              <span
                className={`menu-text ${isAnalyticsOpen ? "text-light" : ""}`}
                style={{ fontSize: "initial" }}
              >
                {i18n("LandingPage.REPORTS")}
              </span>
              {isAsideOpen && (
                <span className="menu-icon">
                  {isAnalyticsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
              )}
            </div>
            {isAnalyticsOpen && isAsideOpen && (
              <div className="menu-submenu">
                <ul className="menu-subnav pt-0">
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/sales-summary",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/analytics/sales-summary"
                      >
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.SalesSummary")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/sales-by-product",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/sales-by-product">
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.SalesByProduct")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/sales-by-category",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/sales-by-category">
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.SalesByCategory")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/sales-by-user",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/sales-by-user">
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.SalesByUser")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/sales-by-payment-channel",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/sales-by-payment-channel"
                      >
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.SalesByPaymentChanel")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/sales-by-time",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/sales-by-time">
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.SalesByTime")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/tax-report",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/tax-report">
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.Taxes")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {rolePayload[6]?.selected && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/analytics/discount-report",
                        false
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/discount-report">
                        <span className="menu-icon"></span>
                        <span
                          className="menu-text"
                          style={{ fontSize: "initial" }}
                        >
                          {i18n("LandingPage.Discounts")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {/* Popover for analytics menu when sidebar is closed */}
            {isAsideOpen === false && (
              <Popover
                placement="right-start"
                isOpen={analyticsPopoverOpen}
                target="AnalyticsPopover"
                toggle={() => setAnalyticsPopoverOpen(!analyticsPopoverOpen)}
                innerRef={popoverRef}
                className="report-popover"
              >
                <PopoverBody>
                  <ul className="menu-subnav report-popover-subnav pt-0">
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/sales-summary",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/analytics/sales-summary"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.SalesSummary")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/sales-by-product",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/sales-by-product"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.SalesByProduct")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/sales-by-category",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/sales-by-category"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.SalesByCategory")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/sales-by-user",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/sales-by-user"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.SalesByUser")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/sales-by-payment-channel",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/sales-by-payment-channel"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.SalesByPaymentChanel")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/sales-by-time",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/sales-by-time"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.SalesByTime")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/tax-report",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/tax-report"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.Taxes")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {rolePayload[6]?.selected && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/analytics/discount-report",
                          false
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/discount-report"
                          onClick={() => setAnalyticsPopoverOpen(false)}
                        >
                          <span className="menu-icon"></span>
                          <span
                            className="menu-text popover-title"
                            style={{ fontSize: "initial" }}
                          >
                            {i18n("LandingPage.Discounts")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </PopoverBody>
              </Popover>
            )}
          </li>
        )}
        {rolePayload[3]?.selected && (
          <li
            style={{
              pointerEvents: (isTrialExpired || isSubscriptionPaused) && "none",
              opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
            }}
            onClick={() => setPopoverOpen(false)}
            className={`menu-item ${getMenuItemActive("/products", true)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/products">
              <span className="menu-icon">
                <img src={IconProducts} alt="#" title="Product" width="20px" />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.MENU")}
              </span>
            </NavLink>
          </li>
        )}
        {rolePayload[4]?.selected && (
          <li
            id="Popover1"
            onClick={handleCompanyMenuClick}
            className={`menu-item ${getMenuItemActive("/company", true)}`}
            aria-haspopup="true"
          >
            <a
              className="menu-link"
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              <span className="menu-icon">
                <img src={IconCompany} alt="#" title="Company" width="20px" />
              </span>
              <span className="menu-text" style={{ fontSize: "initial" }}>
                {i18n("LandingPage.COMPANY")}
              </span>
              {isAsideOpen && (
                <span className="menu-icon">
                  {openCompanyMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
              )}
            </a>
          </li>
        )}
        {isAsideOpen && openCompanyMenu  && (
          <>
            {rolePayload[5]?.selected && (
              <li
                style={{
                  pointerEvents:
                    (isTrialExpired || isSubscriptionPaused) && "none",
                  opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
                }}
                className={`menu-item ${getMenuItemActive(
                  "/information",
                  true
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/information">
                  <span
                    className="menu-text"
                    style={{ fontSize: "initial", marginLeft: "40px" }}
                  >
                    {i18n("Company.Information")}
                  </span>
                </NavLink>
              </li>
            )}
            <li
              className={`menu-item ${getMenuItemActive(
                "/subscription",
                true
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/subscription">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("Company.Subscription")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents:
                  (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive(
                "/payment-channels",
                true
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/payment-channels">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("global.PaymentChannels")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents:
                  (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/taxes", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/taxes">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("MENU.Taxes")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents:
                  (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/stores", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/stores">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("Company.Stores")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents:
                  (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/pos-devices", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/pos-devices">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("Company.PosDevice")}
                </span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/users", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/users">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("Company.Users")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents:
                  (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/roles", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/roles">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("Company.Roles")}
                </span>
              </NavLink>
            </li>
            <li
              style={{
                pointerEvents:
                  (isTrialExpired || isSubscriptionPaused) && "none",
                opacity: (isTrialExpired || isSubscriptionPaused) && 0.6,
              }}
              className={`menu-item ${getMenuItemActive("/log-book", true)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/log-book">
                <span
                  className="menu-text"
                  style={{ fontSize: "initial", marginLeft: "40px" }}
                >
                  {i18n("Company.CompanyLogBook")}
                </span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {isAsideOpen === false &&
        openCompanyMenuList === true &&
        companyPopoverOpen === true && (
          <div className="position-fixed">
            <Popover
              flip
              isOpen={popoverOpen}
              target="Popover1"
              placement="right-start"
              className="aside-popover"
              innerRef={popoverRef}
              dir={user && user?.language === "arabic" ? "rtl" : "ltr"}
            >
              <PopoverBody className="aside-menu-list">
                {[
                  { path: "/information", label: i18n("Company.Information") },
                  {
                    path: "/subscription",
                    label: i18n("Company.Subscription"),
                  },
                  {
                    path: "/payment-channels",
                    label: i18n("global.PaymentChannels"),
                  },
                  { path: "/taxes", label: i18n("MENU.Taxes") },
                  { path: "/stores", label: i18n("Company.Stores") },
                  { path: "/pos-devices", label: i18n("Company.PosDevice") },
                  { path: "/users", label: i18n("Company.Users") },
                  { path: "/roles", label: i18n("Company.Roles") },
                  { path: "/log-book", label: i18n("Company.CompanyLogBook") },
                ].map(({ path, label }, index) => {
                  const isDisabled =
                    (isTrialExpired || isSubscriptionPaused) &&
                    !["/users", "/subscription"].includes(path);

                  return (
                    <p
                      key={index}
                      style={{
                        pointerEvents: isDisabled ? "none" : "auto",
                        opacity: isDisabled ? 0.5 : 1,
                        fontSize: "14px",
                      }}
                      onClick={() => setPopoverOpen(false)}
                    >
                      <Link to={path}>{label}</Link>
                    </p>
                  );
                })}
              </PopoverBody>
            </Popover>
          </div>
        )}

      {/* un comment this when on sprint of the back to free plan */}
      {/* {isAsideOpen && unSubscribeSubscription && (
        <div
          style={{
            height: !openCompanyMenu && "53%",
            display: "flex",
            alignItems: "end",
          }}
        >
          <span className="ml-4 d-flex justify-content-center align-items-center sidebar-subscribe-button">
            {i18n("SubscriptionModals.Subscribe")}
          </span>
        </div>
      )} */}
    </>
  );
}
