import React, { useEffect, useRef, useState } from "react";
import SideCard from "../../../components/Card/SideCard";
import Table from "../../../components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getTableData } from "../../../../_redux/analytics/analyticsService";
import Chart from "react-apexcharts";
import TableFilter from "../../../../assets/img/table-fIlter-icon.svg";
import PageNavigator from "../../../components/pagination/PageNavigator";
import {
  formatNumber,
  formatValue,
  transformColumnName,
} from "../../../../helpers/helpers";
import ElipseIcon from "../../../../assets/img/no-data-icon.svg";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";

const SalesByProduct = () => {
  const dispatch = useDispatch();
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tableType, setTableType] = useState("salesByProduct");
  const { analyticsTableData } = useSelector((state) => state.analytics);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [tableFilter, setTableFilter] = useState([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [topFiveProductSold, setTopFiveProductSold] = useState([]);
  const [timeZone, setTimeZone] = useState("Asia/Riyadh")

  const filterDialogRef = useRef(null);

  // Initialize columns with an empty array
  const columns = [
    "productName",
    "categoryName",
    "productsSold",
    "sku",
    "grossSales",
    "productsRefunded",
    "refunds",
    "discounts",
    "netSales",
    "costOfGoods",
    "grossProfit",
    "margin",
    "tax",
  ];

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate
        ? moment(new Date(startDate)).format("M-DD-yy")
        : "";
      const formattedEndDate = endDate
        ? moment(new Date(endDate)).format("M-DD-yy")
        : "";
      const response = await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          timeZone,
        }),
      );
      const data = response?.payload;
      const topFiveProduct = data[data.length - 1];
      setTopFiveProductSold(topFiveProduct?.top5ProductSold);
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [
    page,
    limit,
    allStores,
    allUsers,
    startDate,
    endDate,
    tableType,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    if (analyticsTableData.length > 0) {
      setTableFilter(columns); // Initialize tableFilter with all columns
    }
  }, [analyticsTableData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDialogRef.current &&
        !filterDialogRef.current.contains(event.target)
      ) {
        setFilterDialogOpen(false);
      }
    };

    if (filterDialogOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterDialogOpen]);

  const handleSelectStores = (storeIds) => {
    setAllStores(storeIds);
  };

  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleSort = (column) => {
    const newSortOrder =
      sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const toggleColumnVisibility = (column) => {
    setTableFilter((prevFilter) =>
      prevFilter.includes(column)
        ? prevFilter.filter((col) => col !== column)
        : [...prevFilter, column]
    );
    // Close the dialog after selecting a column
  };

  const toggleFilterDialog = () => {
    setFilterDialogOpen((prev) => !prev);
  };
  function formatNumberWithCommas(number) {
    return number.toLocaleString();
  }

  // Formatting function
  // const formatValue = (column, value) => {
  //   if (value === undefined || value === null) {
  //     return "";
  //   }

  //   switch (column) {
  //     case "productsSold":
  //     case "productsRefunded":
  //       return parseInt(value, 10); // Simple integer format

  //     case "sku":
  //       return value.toString().padStart(3, "0"); // Padded with leading zeros

  //     case "grossSales":
  //     case "refunds":
  //     case "discounts":
  //     case "netSales":
  //     case "costOfGoods":
  //     case "grossProfit":
  //       return parseFloat(value).toFixed(2); // Two decimal places

  //     case "margin":
  //       return `${parseFloat(value).toFixed(2)}%`; // Two decimal places with %

  //     case "tax":
  //       return parseFloat(value).toFixed(2); // Assuming similar format as financial values

  //     default:
  //       return value; // Default case if no formatting is applied
  //   }
  // };

  // // Format the table data before passing it to the Table component
  // const formattedData = analyticsTableData.map((row) => {
  //   const newRow = {};
  //   columns.forEach((column) => {
  //     newRow[column] = formatValue(column, row[column]);
  //   });
  //   return newRow;
  // });
  const formattedData = () => {
    const allExceptLastRow = analyticsTableData.slice(0, -1);
    return [
      ...allExceptLastRow.map((row) => ({
        ...row,
        productsSold: formatNumberWithCommas(parseFloat(row.productsSold)),
        grossSales: formatValue(row.grossSales),
        productsRefunded: formatNumberWithCommas(
          parseFloat(row.productsRefunded)
        ),
        refunds: formatValue(row.refunds),
        discounts: formatValue(row.discounts),
        netSales: formatValue(row.netSales),
        costOfGoods: formatValue(row.costOfGoods),
        grossProfit: formatValue(row.grossProfit),
        tax: formatValue(row.tax),
        sku: row.sku && row.sku.toString().padStart(4, "0"),
      })),
    ];
  };
  ;

  const filteredColumns = columns.filter((column) =>
    tableFilter.includes(column)
  );

  // const topFiveProductSold =
  //   analyticsTableData.find((item) => item.top5ProductSold)?.top5ProductSold ||
  //   [];
  const chartData = {
    series: Array.isArray(topFiveProductSold)
      ? topFiveProductSold.map((product) => product.grossSales)
      : [],
    options: {
      labels: Array.isArray(topFiveProductSold)
        ? topFiveProductSold.map((product) => product.productName)
        : [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      chart: {
        type: "pie",
        width: 200,
      },
      legend: {
        position: "bottom",
      },
      colors: ["#f2c94c", "#2f80ed", "#bb6bd9", "#6fcf97", "#eb5757"],
      tooltip: {
        y: {
          formatter: function(value) {
            return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
      },
    },
  };

  return (
    <div className="d-flex product-sidecard ">
      <SideCard
        handleSelectStores={handleSelectStores}
        handleSelectUsers={handleSelectUsers}
        allUser={allUsers}
        allStores={allStores}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        page={page}
        limit={limit}
        tableType={tableType}
      />
      <div className="row pl-3 w-100">
        <div className="col-sm-6">
          <div className="card product-card p-4">
            <h3 className="mt-2">Top 5 selling product</h3>
            <div className="card-body pt-5">
              {Array.isArray(topFiveProductSold) &&
              topFiveProductSold.length > 0 ? (
                <ul className="top-products-list">
                  {topFiveProductSold.map((product, index) => (
                    <li key={index} className="top-product-item">
                      <span className="product-dot"></span>
                      <span className="product-name">
                        {product.productName}
                      </span>
                      <span className="product-price">
                        {formatNumber(product.grossSales)}
                        <span className="ml-1">{product.currency || ""}</span>
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="product-img-section">
                  <img className="elipse-icon" src={ElipseIcon} alt="" />
                  <span>There are no sales in the selected time period</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="card chart-card">
            <h3 className="mt-2">Top 5 selling product chart</h3>
            <div className="card-body pt-5 d-flex justify-content-center">
              {Array.isArray(topFiveProductSold) &&
              topFiveProductSold.length > 0 ? (
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="pie"
                  width="350"
                />
              ) : (
                <div className="product-img-section">
                  <img className="elipse-icon" src={ElipseIcon} alt="" />
                  <span>There are no sales in the selected time period</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex table-header justify-content-between">
                <h5
                  className="card-title text-primary mt-1"
                  role="button"
                  onClick={() =>
                    exportTableToPDF("tableToExport", "sales_by_product.pdf")
                  }
                >
                  Export
                </h5>
                <span style={{ position: "relative" }}>
                  <img
                    src={TableFilter}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={toggleFilterDialog}
                  />
                  {filterDialogOpen && (
                    <div
                      className="filter-dialog"
                      style={{ position: "absolute", top: "20px", right: "0" }}
                      ref={filterDialogRef}
                    >
                      <div className="filter-dialog-content">
                        <ul>
                          {columns.map((column, index) => (
                            <li key={column} className="column-item">
                              <label className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  checked={tableFilter.includes(column)}
                                  onChange={() =>
                                    index !== 0 &&
                                    toggleColumnVisibility(column)
                                  }
                                />
                                <span className="ml-2 col-title">
                                  {transformColumnName(column)}
                                </span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </span>
              </div>
            </div>
            <div id="tableToExport">
              <Table
                columns={filteredColumns}
                data={formattedData()} // Pass the formatted data to the Table component
                onSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </div>
            <nav>
              <PageNavigator
                page={page}
                limit={limit}
                handlePageChange={handlePageChange}
                handleLimitChange={handleLimitChange}
                data={formattedData()}
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesByProduct;
