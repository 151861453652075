import React, { useState, useEffect } from "react";
import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getTableData } from "../../../../_redux/analytics/analyticsService";
import PageNavigator from "../../../components/pagination/PageNavigator";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";

const DiscountsReport = () => {
  const dispatch = useDispatch();
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableType, setTableType] = useState("salesByDiscount");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeZone, setTimeZone] = useState("Asia/Riyadh")


  const { analyticsTableData } = useSelector((state) => state.analytics);



  const columns =[
    "discountName",
    "discountRate",
    "discountsApplied",
    "amountDiscounted"
]

useEffect(() => {
  setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
}, []);

  // Format data before passing to Table component
  const formattedData = analyticsTableData.map((row) => ({
    ...row,
    amountDiscounted: row.amountDiscounted !== undefined
    ? row.amountDiscounted === 0
      ? "0.00"
      : row.amountDiscounted.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : "",
    discountRate: row.discountRate
      ? String(row.discountRate).includes("%")
        ? row.discountRate === "0%"
          ? "%"
          : row.discountRate
        : `${row.discountRate} SAR`
      : "SAR",
  }));
  

  // Calculate Grand Totals
  const calculateGrandTotals = (data) => {
    return data.reduce(
      (totals, row) => {
        totals.discountTotal += parseFloat(row.discountsApplied) || 0;
        totals.amountTotal +=
          parseFloat(row.amountDiscounted.replace(/,/g, "")) || 0;
        return totals;
      },
      { discountTotal: 0, amountTotal: 0 }
    );
  };

  const { discountTotal, amountTotal } = calculateGrandTotals(formattedData);

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate
        ? moment(new Date(startDate)).format("M-DD-yy")
        : "";
      const formattedEndDate = endDate
        ? moment(new Date(endDate)).format("M-DD-yy")
        : "";
      await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          timeZone
        }),
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [
    page,
    limit,
    allStores,
    allUsers,
    startDate,
    endDate,
    tableType,
    sortBy,
    sortOrder,
  ]);

  const handleSort = (column) => {
    const newSortOrder =
      sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handleSelectStores = (storeIds) => {
    setAllStores(storeIds);
  };

  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  return (
    <div className="d-flex disc-card">
      <SideCard
        handleSelectStores={handleSelectStores}
        handleSelectUsers={handleSelectUsers}
        allUser={allUsers}
        allStores={allStores}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        page={page}
        limit={limit}
        tableType={tableType}
      />
      <div className="main-content discount-main pl-3 w-100">
        <div className="card discount-card mb-3">
          <div className="card-body discount-cardbody">
            <div className="d-flex justify-content-between">
              <h5
              role="button"
                className="card-title text-primary mt-2 ml-3"
                onClick={() =>
                  exportTableToPDF("tableToExport", "sales_by_discount.pdf")
                }
              >
                Export
              </h5>
            </div>
            <div id="tableToExport">
              <Table
                columns={columns}
                data={formattedData} // Use the formatted data here
                onSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </div>

            {/* Grand Total Display */}
            <div className="d-flex justify-content-between mt-2">
              <span>Total:</span>
              <strong>{discountTotal.toFixed(2)}</strong>
              <strong>{amountTotal.toFixed(2)}</strong>
            </div>
          </div>
          <nav className="mt-5">
            <PageNavigator
              page={page}
              limit={limit}
              handlePageChange={handlePageChange}
              handleLimitChange={handleLimitChange}
              data={formattedData}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DiscountsReport;
