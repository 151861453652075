import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getChartsSalesByTime,
  getTableData,
} from "../../../../_redux/analytics/analyticsService";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";

const SalesByTime = () => {
  const dispatch = useDispatch();
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("Gross sales avg.");
  const [tableType, setTableType] = useState("salesByTime");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeRange, setTimeRange] = useState({ start: null, end: null });
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");

  const { analyticsTableData, grossTimeData } = useSelector(
    (state) => state.analytics
  );

  const columns = [
    "time",
    "grossSalesAvg",
    "refundsAvg",
    "discountsAvg",
    "netSalesAvg",
  ];
  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  const hours = (color) => ({
    xaxis: {
      categories: grossTimeData.map((time) => time?.time),
    },
    dataLabels: {
      enabled: false,
    },
    colors: [color],
  });

  const chartOptions = (color) => ({
    chart: {
      type: "bar",
      height: 350,
    },
    chart: {
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedSeriesIndex = config.seriesIndex;
          const selectedDataPointIndex = config.dataPointIndex;
          const selectedTitle = [
            "Gross sales avg.",
            "Refunds avg.",
            "Discounts avg.",
            "Net sales avg.",
          ][selectedSeriesIndex];
          setSelectedTitle(selectedTitle);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [color],
  });

  const colors = {
    "Gross sales avg.": "#6FCF97",
    "Refunds avg.": "#BB6BD9",
    "Discounts avg.": "#F2994A",
    "Net sales avg.": "#56CCF2",
  };

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate
        ? moment(new Date(startDate)).format("M-DD-yy")
        : "";
      const formattedEndDate = endDate
        ? moment(new Date(endDate)).format("M-DD-yy")
        : "";
      await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          timeRange,
          timeZone
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [
    page,
    limit,
    allStores,
    allUsers,
    startDate,
    endDate,
    tableType,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate
        ? moment(new Date(startDate)).format("M-DD-yy")
        : "";
      const formattedEndDate = endDate
        ? moment(new Date(endDate)).format("M-DD-yy")
        : "";
      await dispatch(
        getChartsSalesByTime({
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          timeZone,
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [allStores, allUsers, startDate, endDate]);

  const formatValue = (value) => {
    if (typeof value !== "number") return value; // Return as is if not a number
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formattedData = analyticsTableData.map((row) => ({
    ...row,
    grossSalesAvg: formatValue(row.grossSalesAvg),
    refundsAvg: formatValue(row.refundsAvg),
    discountsAvg: formatValue(row.discountsAvg),
    netSalesAvg: formatValue(row.netSalesAvg),
  }));

  const handleSelectStores = (storeIds) => {
    setAllStores(storeIds);
  };
  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleSort = (column) => {
    const newSortOrder =
      sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handleTimeRangeChange = (start, end) => {
    setTimeRange({ start, end });
  };

  const filterDataByTimeRange = (data, start, end) => {
    if (!start || !end) return data;

    const startHour = parseInt(start.split(":")[0], 10);
    const endHour = parseInt(end.split(":")[0], 10);

    return data.filter((item) => {
      const itemHour = parseInt(item.time.split(":")[0], 10);
      return itemHour >= startHour && itemHour <= endHour;
    });
  };

  const getChartData = (title) => {
    const filteredData = filterDataByTimeRange(
      grossTimeData,
      timeRange.start,
      timeRange.end
    );
    return filteredData.map((item) => {
      const value =
        title === "Gross sales avg."
          ? item.grossSalesAvg
          : title === "Refunds avg."
          ? item.refundsAvg
          : title === "Discounts avg."
          ? item.discountsAvg
          : item.netSalesAvg;
      return value !== null && value !== undefined
        ? Number(value).toFixed(0)
        : 0;
    });
  };

  const filteredTableData = filterDataByTimeRange(
    formattedData,
    timeRange.start,
    timeRange.end
  );

  return (
    <div className="d-flex time-card w-100">
      <SideCard
        handleSelectStores={handleSelectStores}
        handleSelectUsers={handleSelectUsers}
        allUser={allUsers}
        allStores={allStores}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        page={page}
        limit={limit}
        tableType={tableType}
        onTimeRangeChange={handleTimeRangeChange}
        timeRange={true}
      />
      <div className="main-content ml-2 w-75">
        <div className="d-flex flex-nowrap time-chart">
          {[
            "Gross sales avg.",
            "Refunds avg.",
            "Discounts avg.",
            "Net sales avg.",
          ].map((title, index) => (
            <div
              key={index}
              className={`card ${
                selectedTitle === title ? "selected-chart" : ""
              }`}
              style={{ cursor: "pointer", marginBottom: "1rem" }}
              onClick={() => setSelectedTitle(title)}
            >
              <h5 className="card-title">{title}</h5>
              <div className="card-body d-flex justify-content-center align-items-center">
                <Chart
                  type="bar"
                  options={chartOptions(colors[title])}
                  series={[
                    {
                      name: title,
                      data: getChartData(title),
                    },
                  ]}
                  width="300"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="card mb-3" style={{ width: "100%", height: "210px" }}>
          <span className="font-weight-bolder">{selectedTitle}</span>
          <div className="card-body">
            <div style={{ marginTop: "-24px" }}>
              <Chart
                series={[
                  {
                    name: selectedTitle,
                    data: getChartData(selectedTitle),
                  },
                ]}
                type="bar"
                options={hours(colors[selectedTitle])}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body p-0 ">
            <h5
              className="card-title text-primary"
              role="button"
              onClick={() =>
                exportTableToPDF("tableToExport", "sales_by_time.pdf")
              }
            >
              Export
            </h5>
            <div id="tableToExport">
              <Table
                columns={columns}
                data={filteredTableData}
                onSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            </div>
          </div>
          <nav>
            {/* <PageNavigator
              page={page}
              limit={limit}
              handlePageChange={handlePageChange}
              handleLimitChange={handleLimitChange}
            /> */}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SalesByTime;
