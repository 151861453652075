import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import NavToggler from "../../../../assets/img/nav-toggler.png";
import fatoratyLogo from "../../../../assets/img/logo-light.svg";
import { useAside } from "../AsideContext";
import { useDispatch, useSelector } from "react-redux";
import {
  expiredSubscriptionWithTwoMinutes,
  getSubscription,
  pausedSubscription,
} from "../../../../_redux/subscription/subscriptionService";

export function Brand() {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);
  const { subscriptionData } = useSelector((state) => state.subscription);
  const location = useLocation();
  const { pathname } = location;

  // Define a mapping of specific paths to display text
  const pathToTextMapping = {
    "analytics/sales-summary": "Sales by Summary",
    "sales-by-product": "Sales by Product",
    "sales-by-category": "Sales by Category",
    "sales-by-user": "Sales by User",
    "sales-by-payment-channel": "Sales by Payment Channel",
    "sales-by-time": "Sales by Time",
    "tax-report": "Taxes",
    "discount-report": "Discounts",
  };

  // Extract the full path after the base URL
  const pathSegment = pathname
    .split("/")
    .filter(Boolean)
    .join("/");

  // Get the display text from the mapping
  const displayText =
    pathToTextMapping[pathSegment] ||
    pathSegment
      .split("/")
      .pop()
      .replace("-", " ")
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

  const uiService = useHtmlClassService();
  const { toggleAside, isAsideOpen } = useAside();
  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  const handlePausedSubscription = async () => {
    await dispatch(pausedSubscription());
    await dispatch(getSubscription());
  };

  const handleSubscriptionWithTwoMinutes = async () => {
    await dispatch(expiredSubscriptionWithTwoMinutes()).then(() => {
      dispatch(getSubscription());
    });
  };
  

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses} f-backgroundColor`}
        id="kt_brand"
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
          width: language === "english" && "650px",
        }}
      >
        {/* begin::Logo */}
        <Link to="/dashboard" className="brand-logo">
          <img alt="logo" src={fatoratyLogo} />
        </Link>
        {/* end::Logo */}
        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <span
              onClick={() => toggleAside()}
              className={`aside-toggler px-0 menu-toggleAction f-sideMenu-toggle `}
              id="kt_aside_toggle"
              style={{
                marginLeft: language === "english" && "65px",
                marginRight:
                  isAsideOpen && language === "arabic"
                    ? "70px"
                    : !isAsideOpen && language === "arabic"
                    ? "194px"
                    : "0px",
              }}
            >
              <img src={NavToggler} alt="loading" />
            </span>
            {/* Display the capitalized path segment */}
            <span
              className="ml-4 header-subscription-text"
              style={{
                width: "100%",
                marginRight: language === "arabic" && "20px",
              }}
            >
              {displayText}
            </span>
            {/* end::Toolbar */}
          </>
        )}
        {subscriptionData?.subscription?.subscriptionInfo?.isSubscribed &&
          subscriptionData?.subscription?.currentPlan.status !== "Paused" && (
            <div>
              <button
                className="btn btn-warning"
                onClick={handlePausedSubscription}
              >
                Paused
              </button>
            </div>
          )}
        {subscriptionData?.subscription?.subscriptionInfo?.isSubscribed &&
          subscriptionData?.subscription?.currentPlan.status !== "Paused" && (
            <div className="d-flex flex-row align-items-center w-100">
              <button
                className="btn btn-warning ml-4 btn-sm d-flex align-items-center"
                onClick={handleSubscriptionWithTwoMinutes}
              >
                Expires in 2 mins
              </button>
            </div>
          )}
      </div>
      {/* end::Brand */}
    </>
  );
}
