import React from "react";
import LogoOne from "../../../../assets/img/company/Logo1.svg";
import LogoTwo from "../../../../assets/img/company/Logo2.svg";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import { formatNumber } from "../../../../helpers/constants";

const SubscriptionEnglishInvoice = ({
  rxPrintRef,
  invoice,
  paymentCardLastFourDigit,
  billedTo,
}) => {
  if (!invoice) {
    return null;
  }

  const formattedDate = moment(invoice?.created_at).format("MMM D, YYYY");

  const formattedDateWithTime = moment(invoice?.updated_at).format(
    "D MMM, YYYY HH:mm"
  );

  const invoiceType = invoice?.paymentMetaData?.planType;

  const planType = invoiceType?.split(" ")[0];

  const seats = invoice?.paymentMetaData?.seats || 0;

  const basePrice = invoice?.paymentMetaData?.basePrice || 0;

  const subtotal = seats * basePrice;

  const appliedBalance =
    Math.min(invoice?.paymentMetaData?.creditUsed, subtotal) || 0;

  const amountBeforeTax = Math.abs(subtotal - appliedBalance);

  let tax = amountBeforeTax === 0 ? 0 : invoice?.paymentMetaData?.tax;

  let totalWithTax = amountBeforeTax === 0 ? 0 : amountBeforeTax + tax;

  console.log(totalWithTax, "totalWithTax");

  const paymentAmount = totalWithTax > 0 ? totalWithTax : 0;

  const amountDue = 0;

  const invoiceDateFrom = moment(invoice?.billing_cycle?.from).format(
    "MMM D, YYYY"
  );
  const invoiceDateTo = moment(invoice?.billing_cycle?.till).format(
    "MMM D, YYYY"
  );

  return (
    <>
      <div className="invoice-container" ref={rxPrintRef}>
        <div className="invoice" id="divToPrint">
          <div className="invoice-header">
            <div className="invoice-logos">
              <img src={LogoOne} alt="LogoImage" />
              <img src={LogoTwo} alt="LogoImage" />
            </div>
            <div className="invoice-title">
              <span>Tax Invoice</span>
            </div>
          </div>

          <div className="invoice-info">
            <div className="company-info">
              <span>Numu It Est. - Fatoraty</span>
              <span>Dammam - Saudi Arabia</span>
              <span>CR. 2050139742</span>
              <span>Tax No.</span>
            </div>
            <div className="invoice-details">
              <span>Invoice number: A1- {invoice?.invoiceNumber}</span>
              <span>Date: {formattedDate}</span>
              <span>Date of supply: {formattedDate}</span>
              <span>Customer No. 1000005</span>
              <span className="status-paid">{invoice?.status}</span>
            </div>
          </div>

          <div className="billing-info">
            <div className="billed-to">
              <span className="title">Billed to:</span>
              <span>{billedTo?.companyName}</span>
              <span>Tax no. {billedTo?.taxNumber}</span>
              <span>{billedTo?.country}</span>
            </div>
            <div className="subscription-info">
              <span className="title">Subscription:</span>
              <span>ID: QzqKjkR2pnkMr1Syu</span>
              <span>
                Billing Period: {invoiceDateFrom} , {invoiceDateTo}
              </span>
              <span>Next Billing Date: {invoiceDateTo}</span>
            </div>
          </div>

          <table className="invoice-table">
            <thead>
              <tr>
                <th className="description">Description</th>
                <th className="units">Units</th>
                <th className="unit-price">Unit price</th>
                <th className="amount">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="description">Users management - {planType}</td>
                <td className="units">{seats} User</td>
                <td className="unit-price">SAR {formatNumber(basePrice)}</td>
                <td className="amount">SAR {formatNumber(subtotal)}</td>
              </tr>
            </tbody>
          </table>

          <div className="total-info">
            <div className="totals">
              {appliedBalance > 0 && <span className="title">Sub total</span>}
              {appliedBalance > 0 && <span>Credits Applied</span>}
              <span className="title">Total before tax</span>
              <span>VAT. 15%</span>
              <span className="title">Total with tax</span>
              {paymentAmount > 0 && <span>Payments</span>}
              <span className="title amount-due">Amount due</span>
            </div>
            <div className="amounts">
              {appliedBalance > 0 && (
                <span className="title">SAR {formatNumber(subtotal)}</span>
              )}
              {appliedBalance > 0 && (
                <span>(SAR {formatNumber(appliedBalance)})</span>
              )}
              <span className="title">SAR {formatNumber(amountBeforeTax)}</span>
              <span>SAR {formatNumber(tax)}</span>
              <span className="title">SAR {formatNumber(totalWithTax)}</span>
              {paymentAmount > 0 && (
                <span>(SAR {formatNumber(paymentAmount)})</span>
              )}
              <span className="title amount-due">
                SAR {formatNumber(amountDue)}
              </span>
            </div>
          </div>
          <div className="credits-info">
            <p className="credits-title">Credits & Payments:</p>
            {appliedBalance > 0 && (
              <p>
                <span>A credit of</span>{" "}
                <span className="title">
                  SAR {formatNumber(appliedBalance)}
                </span>{" "}
                <span>was applied on {formattedDateWithTime} KSA.</span>
              </p>
            )}
            {paymentAmount > 0 && (
              <p>
                <span className="title">SAR {formatNumber(paymentAmount)}</span>{" "}
                <span>
                  was paid on {formattedDateWithTime} KSA by Visa card ending{" "}
                  {paymentCardLastFourDigit}.
                </span>
              </p>
            )}
            <div className="invoice-qr-container">
              <QRCodeSVG
                value={
                  "Numu It Est. - Fatoraty, Dammam - Saudi Arabia, CR. 2050139742"
                }
                size={100}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionEnglishInvoice;
