import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ANALYTICS_URL = "api/analytics";

export const getTableData = createAsyncThunk(
  "analytics/getTablesData",
  async ({ page, limit, allStores, allUsers, startDate, endDate, tableType, sortBy, sortOrder, groupBy, timeZone }, { rejectWithValue }) => {
    let url = `${ANALYTICS_URL}/getTablesData?tableType=${tableType}&page=${page}&size=${limit}&startDate=${startDate}&endDate=${endDate}&stores=${allStores}&users=${allUsers}&groupBy=${groupBy}&timeZone=${timeZone}`;
    if (sortBy) {
      url += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    }

    const response = await axios.get(url);
    const data = response.data;
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(data);
    }
    return data;
  }
);

export const getGrossSalesChartData = createAsyncThunk(
  "analytics/getChartsSalesSummary",
  async ({startDate, endDate, allUsers, allStores, timeZone}, { rejectWithValue }) => {
    const response = await axios.get(
      `${ANALYTICS_URL}/getChartsSalesSummary?startDate=${startDate}&endDate=${endDate}&stores=${allStores}&users=${allUsers}&timeZone=${timeZone}`
    );
    const data = response.data;
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(data);
    }
    return data;
  }
);

export const getChartsSalesByTime = createAsyncThunk(
  "analytics/getChartsSalesByTime",
  async ({startDate, endDate, allUsers, allStores, timeZone}, { rejectWithValue }) => {
    const response = await axios.get(
      `${ANALYTICS_URL}/getChartsSalesByTime?startDate=${startDate}&endDate=${endDate}&stores=${allStores}&users=${allUsers}&timeZone=${timeZone}`
    );
    const data = response.data;
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(data);
    }
    return data;
  }
);

export const getAllStores = createAsyncThunk(
  "analytics/getAllStores",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ANALYTICS_URL}/getAllStores`);
      return response.data.allStores;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "analytics/getAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ANALYTICS_URL}/getAllUsers`);
      return response.data.allUsers;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);