import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import FileIcon from "../../../../assets/img/file-icon.svg";
import "./analytics.css";
import SideCard from "../../../components/Card/SideCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getTableData,
  getGrossSalesChartData,
  getAllStores,
  getAllUsers,
} from "../../../../_redux/analytics/analyticsService";
import moment from "moment/moment";
import TableFilter from "../../../../assets/img/table-fIlter-icon.svg";
import "./style.css";
import PageNavigator from "../../../components/pagination/PageNavigator";
import Table from "../../../components/Table/Table";
import SidebarDialog from "../../../../_metronic/layout/components/dialog/SidebarDialog";
import { formatNumber, formatValue } from "../../../../helpers/helpers";
import { exportTableToPDF } from "../../../../utils/pdfExportUtil";
const AnalyticsView = () => {
  const dispatch = useDispatch();
  const {
    analyticsTableData,
    salesChartData,
    grossSalesChartData,
    stores,
    users,
  } = useSelector((state) => state.analytics);
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [tableType, setTableType] = useState("salesBySummary");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedChart, setSelectedChart] = useState("grossSales");
  const [selectedTimeRange, setSelectedTimeRange] = useState("daily");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  useEffect(() => {
    dispatch(getAllStores());
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate
        ? moment(new Date(startDate)).format("M-DD-yy")
        : "";
      const formattedEndDate = endDate
        ? moment(new Date(endDate)).format("M-DD-yy")
        : "";
      await dispatch(
        getTableData({
          tableType,
          page,
          limit,
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sortBy,
          sortOrder,
          groupBy: selectedTimeRange,
          timeZone,
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [
    page,
    limit,
    allStores,
    allUsers,
    startDate,
    endDate,
    tableType,
    sortBy,
    sortOrder,
    selectedTimeRange,
  ]);
  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate
        ? moment(new Date(startDate)).format("M-DD-yy")
        : "";
      const formattedEndDate = endDate
        ? moment(new Date(endDate)).format("M-DD-yy")
        : "";
      await dispatch(
        getGrossSalesChartData({
          allStores,
          allUsers,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          timeRange: selectedTimeRange,
          timeZone,
        })
      );
    };
    if (startDate && endDate) {
      fetchData();
    }
  }, [allStores, allUsers, startDate, endDate]);

  useEffect(() => {
    if (analyticsTableData.length > 0) {
      setVisibleColumns(columns);
    }
  }, [analyticsTableData]);

  let newColumnName;

  if (selectedTimeRange === "hourly") {
    newColumnName = "hour";
  } else {
    newColumnName = "date";
  }
  const columns = [
    newColumnName,
    "grossSales",
    "refunds",
    "discountTotal",
    "netSales",
    "tax",
    "costOfGoods",
    "grossProfit",
    "margin",
  ];

  const formatNumber = (num) => {
    if (num === null || num === undefined || isNaN(num)) return "";
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function formatWeeklyRange(date) {
    const startOfWeek = moment(date)
      .startOf("week")
      .format("D MMM");
    const endOfWeek = moment(date)
      .endOf("week")
      .format("D MMM");
    return `${startOfWeek} - ${endOfWeek}`;
  }

  // Prepare formatted data for the table
  const formattedTableData = analyticsTableData.map((row) => {
    let formattedDate = "";

    switch (selectedTimeRange) {
      case "daily":
        formattedDate = moment(row.date).format("D MMM YYYY");
        break;
      case "weekly":
        formattedDate = formatWeeklyRange(row.date);
        break;
      case "hourly":
        formattedDate = moment(row.hour, "YYYY-MM-DD HH:mm").format("HH:mm");
        break;
      case "monthly":
        formattedDate = moment(row.date).format("MMM yyyy");
        break;
      case "quarterly":
        if (typeof row.date === "string" && row.date.includes("-")) {
          const [year, quarter] = row.date.split("-");
          formattedDate = quarterToRange(quarter, year);
        }
      default:
        formattedDate = row.date;
    }

    return {
      ...row,
      date: formattedDate,
      hour: formattedDate,
      grossSales: formatNumber(row.grossSales),
      refunds: formatNumber(row.refunds),
      discounts: formatNumber(row.discounts),
      netSales: formatNumber(row.netSales),
      tax: formatNumber(parseFloat(row.tax)),
      costOfGoods: formatNumber(row.costOfGoods),
      grossProfit: formatNumber(row.grossProfit),
      margin: row.margin ? `${formatNumber(row.margin)} %` : "",
      netTaxes: formatNumber(row.netTaxes),
    };
  });

  const getChartColor = (chartType) => {
    switch (chartType) {
      case "grossSales":
        return "#AE82D9";
      case "refunds":
        return "#6FCF97";
      case "discounts":
        return "#F2994A";
      case "netSales":
        return "#56CCF2";
      default:
        return "#6FCF97";
    }
  };

  const commonOptions = {
    xaxis: {
      categories: [],

      labels: {
        formatter: function(value) {
          // Convert the value to a comma-separated format
          return value.toLocaleString();
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom:{
        enabled: false,
      },
    },
    stroke: {
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [getChartColor(selectedChart)],
    markers: {
      size: [3, 0],
      colors: ["#f7f9fa"],
      strokeColor: getChartColor(selectedChart),
      strokeWidth: 2,
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
  };

  const calculateGap = (totalDays) => {
    if (totalDays <= 31) return 1;
    if (totalDays >= 32 && totalDays <= 61) return 2;
    if (totalDays >= 62 && totalDays <= 92) return 3;
    if (totalDays >= 93 && totalDays <= 123) return 4;
    if (totalDays >= 124 && totalDays <= 154) return 5;
    if (totalDays >= 155 && totalDays <= 185) return 6;
    if (totalDays >= 186 && totalDays <= 216) return 7;
    if (totalDays >= 217 && totalDays <= 247) return 8;
    if (totalDays >= 248 && totalDays <= 278) return 9;
    if (totalDays >= 279 && totalDays <= 309) return 10;

    if (totalDays >= 310 && totalDays <= 340) return 11;
    if (totalDays >= 341 && totalDays <= 371) return 12;
    return 20; // For more than 360 days
  };
  const getFilteredCategories = (categories) => {
    const totalDays = categories.length;
    const gap = calculateGap(totalDays);

    return categories.filter((_, index) => index % gap === 0);
  };
  const getSeries = (chartType) => {
    if (!grossSalesChartData[selectedTimeRange]) return [];

    const timeRangeData = grossSalesChartData[selectedTimeRange];
    const categories = Object.keys(timeRangeData);
    const filteredCategories = getFilteredCategories(categories);

    const seriesData = filteredCategories.map((date) => {
      const data = timeRangeData[date];
      switch (chartType) {
        case "grossSales":
          return data.grossSales ? data.grossSales.toFixed(0) : 0;
        case "refunds":
          return data.refunds ? data.refunds.toFixed(0) : 0;
        case "discounts":
          return data.discountTotal ? data.discountTotal.toFixed(0) : 0;
        case "netSales":
          return data.netSales ? data.netSales.toFixed(0) : 0;
        default:
          return 0;
      }
    });

    return [
      {
        name: chartType.charAt(0).toUpperCase() + chartType.slice(1),
        data: seriesData,
      },
    ];
  };

  const handleSelectStores = (storeIds) => {
    console.log("selected", storeIds);
    setAllStores(storeIds);
  };

  const handleSelectUsers = (userIds) => {
    setAllUsers(userIds);
  };

  const handleChartClick = (chartType) => {
    setSelectedChart(chartType);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSort = (column) => {
    const order = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(order);
  };

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns((prev) =>
      prev.includes(column)
        ? prev.filter((col) => col !== column)
        : [...prev, column]
    );
    setIsSidebarOpen(false);
  };

  function quarterToRange(quarter, year) {
    let range = "";

    switch (quarter) {
      case "Q1":
        range = `Jan-Mar ${year}`;
        break;
      case "Q2":
        range = `Apr-Jun ${year}`;
        break;
      case "Q3":
        range = `Jul-Sep ${year}`;
        break;
      case "Q4":
        range = `Oct-Dec ${year}`;
        break;
      default:
        range = `Invalid Quarter`;
    }

    return range;
  }

  return (
    <>
      <div className="d-flex sales-summary-card">
        <SideCard
          handleSelectStores={handleSelectStores}
          handleSelectUsers={handleSelectUsers}
          allUser={allUsers}
          allStores={allStores}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          stores={stores}
          users={users}
          page={page}
          limit={limit}
          tableType={tableType}
          grossSalesChartData={grossSalesChartData}
          showTimeRange={true}
          shouldSetDailyTimeRange={true}
          selectedTimeRange={selectedTimeRange}
          setSelectedTimeRange={setSelectedTimeRange}
          timeZone={timeZone}
        />
        <div className="main-content pl-3 w-100">
          <div className="row">
            {["grossSales", "refunds", "discounts", "netSales"].map(
              (chartType) => (
                <div
                  key={chartType}
                  className={`col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mb-3`}
                >
                  <div
                    className={`card-content card ${
                      selectedChart === chartType
                        ? "selectedChart"
                        : "shadow-none"
                    }`}
                    style={{ height: "185px", cursor: "pointer" }}
                    onClick={() => handleChartClick(chartType)}
                  >
                    <div className="card-bodys ">
                      <span className="d-flex justify-content-between">
                        <span className="font-weight-bold">
                          {chartType === "grossSales"
                            ? "Gross Sales"
                            : chartType === "refunds"
                            ? "Refunds"
                            : chartType === "discounts"
                            ? "Discounts"
                            : "Net Sales"}
                        </span>

                        <span className="font-weight-bolder">
                          {(() => {
                            let value;

                            switch (chartType) {
                              case "grossSales":
                                value =
                                  salesChartData?.totalResults?.totalSales;
                                break;
                              case "refunds":
                                value =
                                  salesChartData?.totalResults?.totalRefunds;
                                break;
                              case "discounts":
                                value =
                                  salesChartData?.totalResults?.totalDiscounts;
                                break;
                              case "netSales":
                                value =
                                  salesChartData?.totalResults?.totalNetSales;
                                break;
                              default:
                                value = null;
                            }

                            // Check if the value is null or NaN, and format accordingly
                            return formatNumber(
                              isNaN(value) || value === null ? 0 : value
                            );
                          })()}
                        </span>
                      </span>
                      <span
                        className={`d-flex justify-content-end ${
                          chartType === "grossSales"
                            ? `text-success`
                            : chartType === "refunds"
                            ? `text-danger`
                            : chartType === "discounts"
                            ? `text-danger`
                            : chartType === "netSales"
                            ? `text-success`
                            : "0.00"
                        }`}
                      >
                        <span
                          className={
                            chartType === "grossSales"
                              ? salesChartData?.totalResults?.totalSales -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalSales >
                                0
                                ? "text-success"
                                : salesChartData?.totalResults?.totalSales -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalSales <
                                  0
                                ? "text-danger"
                                : "text-dark"
                              : chartType === "refunds"
                              ? salesChartData?.totalResults?.totalRefunds -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalRefunds >
                                0
                                ? "text-success"
                                : salesChartData?.totalResults?.totalRefunds -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalRefunds <
                                  0
                                ? "text-danger"
                                : "text-dark"
                              : chartType === "discounts"
                              ? salesChartData?.totalResults?.totalDiscounts -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalDiscounts >
                                0
                                ? "text-success"
                                : salesChartData?.totalResults?.totalDiscounts -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalDiscounts <
                                  0
                                ? "text-danger"
                                : "text-dark"
                              : chartType === "netSales"
                              ? salesChartData?.totalResults?.totalNetSales -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalNetSales >
                                0
                                ? "text-success"
                                : salesChartData?.totalResults?.totalNetSales -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalNetSales <
                                  0
                                ? "text-danger"
                                : "text-dark"
                              : ""
                          }
                        >
                          <span className="mr-2">
                            (
                            {chartType === "grossSales"
                              ? `${formatValue(
                                  isNaN(
                                    (salesChartData?.totalResults?.totalSales -
                                      salesChartData?.prevTotalResults
                                        ?.prevTotalSales) /
                                      salesChartData?.totalResults?.totalSales
                                  )
                                    ? 0
                                    : ((salesChartData?.totalResults
                                        ?.totalSales -
                                        salesChartData?.prevTotalResults
                                          ?.prevTotalSales) /
                                        salesChartData?.totalResults
                                          ?.totalSales) *
                                        100
                                )} %`
                              : chartType === "refunds"
                              ? `${formatValue(
                                  isNaN(
                                    (salesChartData?.totalResults
                                      ?.totalRefunds -
                                      salesChartData?.prevTotalResults
                                        ?.prevTotalRefunds) /
                                      salesChartData?.totalResults?.totalRefunds
                                  )
                                    ? 0
                                    : ((salesChartData?.totalResults
                                        ?.totalRefunds -
                                        salesChartData?.prevTotalResults
                                          ?.prevTotalRefunds) /
                                        salesChartData?.totalResults
                                          ?.totalRefunds) *
                                        100
                                )} %`
                              : chartType === "discounts"
                              ? `${formatValue(
                                  isNaN(
                                    (salesChartData?.totalResults
                                      ?.totalDiscounts -
                                      salesChartData?.prevTotalResults
                                        ?.prevTotalDiscounts) /
                                      salesChartData?.totalResults
                                        ?.totalDiscounts
                                  )
                                    ? 0
                                    : ((salesChartData?.totalResults
                                        ?.totalDiscounts -
                                        salesChartData?.prevTotalResults
                                          ?.prevTotalDiscounts) /
                                        salesChartData?.totalResults
                                          ?.totalDiscounts) *
                                        100
                                )} %`
                              : chartType === "netSales"
                              ? `${formatValue(
                                  isNaN(
                                    (salesChartData?.totalResults
                                      ?.totalNetSales -
                                      salesChartData?.prevTotalResults
                                        ?.prevTotalNetSales) /
                                      salesChartData?.totalResults
                                        ?.totalNetSales
                                  )
                                    ? 0
                                    : ((salesChartData?.totalResults
                                        ?.totalNetSales -
                                        salesChartData?.prevTotalResults
                                          ?.prevTotalNetSales) /
                                        salesChartData?.totalResults
                                          ?.totalNetSales) *
                                        100
                                )} %`
                              : "0.00"}
                            )
                          </span>
                        </span>

                        <span
                          className={
                            (chartType === "grossSales" &&
                              salesChartData?.totalResults?.totalSales -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalSales ===
                                0) ||
                            (chartType === "refunds" &&
                              salesChartData?.totalResults?.totalRefunds -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalRefunds ===
                                0) ||
                            (chartType === "discounts" &&
                              salesChartData?.totalResults?.totalDiscounts -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalDiscounts ===
                                0) ||
                            (chartType === "netSales" &&
                              salesChartData?.totalResults?.totalNetSales -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalNetSales ===
                                0)
                              ? "text-dark"
                              : (chartType === "grossSales" &&
                                  salesChartData?.totalResults?.totalSales -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalSales >
                                    0) ||
                                (chartType === "refunds" &&
                                  salesChartData?.totalResults?.totalRefunds -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalRefunds >
                                    0) ||
                                (chartType === "discounts" &&
                                  salesChartData?.totalResults?.totalDiscounts -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalDiscounts >
                                    0) ||
                                (chartType === "netSales" &&
                                  salesChartData?.totalResults?.totalNetSales -
                                    salesChartData?.prevTotalResults
                                      ?.prevTotalNetSales >
                                    0)
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {chartType === "grossSales"
                            ? `${formatValue(
                                salesChartData?.totalResults?.totalSales -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalSales
                              )} ${
                                salesChartData?.totalResults?.totalSales -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalSales >
                                0
                                  ? "+"
                                  : ""
                              }`
                            : chartType === "refunds"
                            ? `${formatValue(
                                salesChartData?.totalResults?.totalRefunds -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalRefunds
                              )} ${
                                salesChartData?.totalResults?.totalRefunds -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalRefunds >
                                0
                                  ? "+"
                                  : ""
                              }`
                            : chartType === "discounts"
                            ? `${formatValue(
                                salesChartData?.totalResults?.totalDiscounts -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalDiscounts
                              )} ${
                                salesChartData?.totalResults?.totalDiscounts -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalDiscounts >
                                0
                                  ? "+"
                                  : ""
                              }`
                            : chartType === "netSales"
                            ? `${formatValue(
                                salesChartData?.totalResults?.totalNetSales -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalNetSales
                              )} ${
                                salesChartData?.totalResults?.totalNetSales -
                                  salesChartData?.prevTotalResults
                                    ?.prevTotalNetSales >
                                0
                                  ? "+"
                                  : ""
                              }`
                            : "0.00"}
                        </span>
                      </span>
                      <div
                        className="position-relative pr-5"
                        style={{ marginTop: "-30px" }}
                      >
                        <Chart
                          series={getSeries(chartType)}
                          type="area"
                          options={{
                            ...commonOptions,
                            xaxis: {
                              categories: Object.keys(
                                grossSalesChartData[selectedTimeRange] || []
                              ),
                              labels: {
                                show: false,
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                            yaxis: {
                              labels: {
                                show: false,
                              },
                            },
                            stroke: {
                              width: 2,
                              curve: "straight",
                            },
                            colors: [getChartColor(chartType)],
                            markers: {
                              size: 0,
                              strokeColor: getChartColor(chartType),
                            },
                          }}
                          height={"100%"}
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>

          <div
            className="card wide-card-chart mb-3"
            style={{ width: "100%", height: "210px" }}
          >
            <div className="card-bodys">
              <span className="d-flex justify-content-between">
                <span className="font-weight-bolder">
                  {selectedChart === "grossSales"
                    ? "Gross Sales"
                    : selectedChart === "refunds"
                    ? "Refunds"
                    : selectedChart === "discounts"
                    ? "Discounts"
                    : "Net Sales"}
                </span>
                <span>
                  <span
                    className={`wide-chart-prevamount ${
                      selectedChart === "grossSales"
                        ? `text-success`
                        : selectedChart === "refunds"
                        ? `text-danger`
                        : selectedChart === "discounts"
                        ? `text-danger`
                        : selectedChart === "netSales"
                        ? `text-success`
                        : "text-body"
                    }`}
                  >
                    <span>
                      <span
                        className={
                          (selectedChart === "grossSales" &&
                            salesChartData?.totalResults?.totalSales -
                              salesChartData?.prevTotalResults?.prevTotalSales >
                              0) ||
                          (selectedChart === "refunds" &&
                            salesChartData?.totalResults?.totalRefunds -
                              salesChartData?.prevTotalResults
                                ?.prevTotalRefunds >
                              0) ||
                          (selectedChart === "discounts" &&
                            salesChartData?.totalResults?.totalDiscounts -
                              salesChartData?.prevTotalResults
                                ?.prevTotalDiscounts >
                              0) ||
                          (selectedChart === "netSales" &&
                            salesChartData?.totalResults?.totalNetSales -
                              salesChartData?.prevTotalResults
                                ?.prevTotalNetSales >
                              0)
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        (
                        {selectedChart === "grossSales"
                          ? `${formatValue(
                              ((salesChartData?.totalResults?.totalSales -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalSales) /
                                salesChartData?.totalResults?.totalSales) *
                                100
                            )} %`
                          : selectedChart === "refunds"
                          ? `${formatValue(
                              ((salesChartData?.totalResults?.totalRefunds -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalRefunds) /
                                salesChartData?.totalResults?.totalRefunds) *
                                100
                            )} %`
                          : selectedChart === "discounts"
                          ? `${formatValue(
                              ((salesChartData?.totalResults?.totalDiscounts -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalDiscounts) /
                                salesChartData?.totalResults?.totalDiscounts) *
                                100
                            )} %`
                          : selectedChart === "netSales"
                          ? `${formatValue(
                              ((salesChartData?.totalResults?.totalNetSales -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalNetSales) /
                                salesChartData?.totalResults?.totalNetSales) *
                                100
                            )} %`
                          : 0}
                        ){" "}
                      </span>
                      <span
                        className={
                          (selectedChart === "grossSales" &&
                            salesChartData?.totalResults?.totalSales -
                              salesChartData?.prevTotalResults?.prevTotalSales >
                              0) ||
                          (selectedChart === "refunds" &&
                            salesChartData?.totalResults?.totalRefunds -
                              salesChartData?.prevTotalResults
                                ?.prevTotalRefunds >
                              0) ||
                          (selectedChart === "discounts" &&
                            salesChartData?.totalResults?.totalDiscounts -
                              salesChartData?.prevTotalResults
                                ?.prevTotalDiscounts >
                              0) ||
                          (selectedChart === "netSales" &&
                            salesChartData?.totalResults?.totalNetSales -
                              salesChartData?.prevTotalResults
                                ?.prevTotalNetSales >
                              0)
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {selectedChart === "grossSales"
                          ? `${formatValue(
                              salesChartData?.totalResults?.totalSales -
                                salesChartData?.prevTotalResults?.prevTotalSales
                            )}`
                          : selectedChart === "refunds"
                          ? `${formatValue(
                              salesChartData?.totalResults?.totalRefunds -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalRefunds
                            )}`
                          : selectedChart === "discounts"
                          ? `${formatValue(
                              salesChartData?.totalResults?.totalDiscounts -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalDiscounts
                            )}`
                          : selectedChart === "netSales"
                          ? `${formatValue(
                              salesChartData?.totalResults?.totalNetSales -
                                salesChartData?.prevTotalResults
                                  ?.prevTotalNetSales
                            )}`
                          : "0.00"}
                      </span>
                    </span>
                  </span>
                  <span className="wide-chart-amonut ml-2">
                    {selectedChart === "grossSales"
                      ? `${formatNumber(
                          salesChartData?.totalResults?.totalSales
                        )}`
                      : selectedChart === "refunds"
                      ? `${formatNumber(
                          salesChartData?.totalResults?.totalRefunds
                        )}`
                      : selectedChart === "discounts"
                      ? `${formatNumber(
                          salesChartData?.totalResults?.totalDiscounts
                        )}`
                      : selectedChart === "netSales"
                      ? `${formatNumber(
                          salesChartData?.totalResults?.totalNetSales
                        )}`
                      : "0.00"}
                  </span>
                </span>
              </span>

              <div style={{ marginTop: "-24px" }}>
                <Chart
                  series={getSeries(selectedChart)}
                  type="area"
                  options={{
                    ...commonOptions,
                    yaxis: {
                      labels: {
                        formatter: function(value) {
                          // Convert the value to a comma-separated format
                          return value.toLocaleString();
                        },
                      },
                    },
                    xaxis: {
                      categories: getFilteredCategories(
                        Object.keys(
                          grossSalesChartData[selectedTimeRange] || {}
                        )
                      ),
                      labels: {
                        formatter: function(value) {
                          if (
                            typeof value === "string" &&
                            value.includes("-")
                          ) {
                            const [year, quarter] = value.split("-");

                            if (selectedTimeRange === "quarterly") {
                              return quarterToRange(quarter, year);
                            }
                          }

                          if (selectedTimeRange === "daily") {
                            return moment(value).format("D MMM");
                          } else if (selectedTimeRange === "hourly") {
                            return moment(value, "YYYY-MM-DD HH:mm").format(
                              "HH:mm"
                            );
                          } else if (selectedTimeRange === "monthly") {
                            return moment(value).format("MMM yyyy");
                          } else if (selectedTimeRange === "weekly") {
                            return formatWeeklyRange(value);
                          } else {
                            return value;
                          }
                        },
                      },
                      tooltip: {
                        enabled: false,
                      },
                    },
                    markers: {
                      size: 3,
                    },
                  }}
                  width={"100%"}
                  height={"120%"}
                />
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h5
                  className="card-title text-primary"
                  role="button"
                  onClick={() =>
                    exportTableToPDF("tableToExport", "sales_by_summary.pdf")
                  }
                >
                  Export
                </h5>
                <span className="table-actions">
                  <img
                    src={TableFilter}
                    alt="Table Filter"
                    className="table-filter-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsSidebarOpen(true)}
                  />
                </span>
              </div>
              <div id="tableToExport">
                <Table
                  columns={columns.filter((column) =>
                    visibleColumns.includes(column)
                  )}
                  data={formattedTableData}
                  onSort={handleSort}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
              </div>
              <nav>
                <PageNavigator
                  page={page}
                  limit={limit}
                  handlePageChange={handlePageChange}
                  handleLimitChange={handleLimitChange}
                  data={analyticsTableData}
                />
              </nav>
            </div>
          </div>
        </div>
        {isSidebarOpen && (
          <SidebarDialog
            columns={columns}
            visibleColumns={visibleColumns}
            handleColumnVisibilityChange={handleColumnVisibilityChange}
            isSidebarOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default AnalyticsView;
