import React, { useState } from "react";
import Select from "react-select";
import WatchIcon from "../../../../assets/img/watch-icon.svg"
import ArrowDown from "../../../../assets/img/arrow-down.svg"

const generateHourLabels = () => {
  return Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0");
    return { value: `${hour}:00`, label: `${hour}:00` };
  });
};

const TimeRangePicker = ({ onTimeRangeChange }) => {
  const [selectedOption, setSelectedOption] = useState("allDay");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "allDay") {
      onTimeRangeChange(null, null);
      setIsPickerOpen(false);
    } else {
      setIsPickerOpen(true);
    }
  };

  const handleStartTimeChange = (selectedOption) => {
    setStartTime(selectedOption);
    onTimeRangeChange(selectedOption.value, endTime ? endTime.value : null);
  };

  const handleEndTimeChange = (selectedOption) => {
    setEndTime(selectedOption);
    onTimeRangeChange(startTime ? startTime.value : null, selectedOption.value);
    setIsPickerOpen(false); 
  };

  const displayValue = () => {
    if (selectedOption === "allDay") {
      return "All day";
    } else if (startTime && endTime) {
      return `${startTime.label} - ${endTime.label}`;
    } else {
      return "Select time range";
    }
  };

  return (
    <div className="custom-time-picker">
      <div className="selected-range">
        <div className="input-container" onClick={() => setIsPickerOpen(!isPickerOpen)}>
          <img src={WatchIcon} alt="" className="icon clock-icon"/>
          <input type="text" readOnly value={displayValue()} />
          <img src={ArrowDown} className="icon arrow-icon" />
        </div>
      </div>
      {isPickerOpen && (
        <div className="picker-options">
          <div className="option">
            <input
              type="radio"
              id="allDay"
              name="timeRange"
              value="allDay"
              checked={selectedOption === "allDay"}
              onChange={handleOptionChange}
            />
            <label htmlFor="allDay">All day</label>
          </div>
          <div className="option">
            <input
              type="radio"
              id="customRange"
              name="timeRange"
              value="customRange"
              checked={selectedOption === "customRange"}
              onChange={handleOptionChange}
            />
            <label htmlFor="customRange">Custom range</label>
          </div>
          {selectedOption === "customRange" && (
            <div className="time-range">
              <div className="time-select">
                <label htmlFor="start">Start</label>
                <Select
                  options={generateHourLabels()}
                  value={startTime}
                  onChange={handleStartTimeChange}
                  placeholder="Start Time"
                  className="select"
                />
              </div>
              <div className="time-select">
                <label htmlFor="end">End</label>
                <Select
                  options={generateHourLabels()}
                  value={endTime}
                  onChange={handleEndTimeChange}
                  placeholder="End Time"
                  className="select"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeRangePicker;
