import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../../private/languageSelector";
import IconCross from "../../../../../assets/img/cross.svg";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { Input } from "../../../../../_metronic/_partials/controls";
import { PAYMENT_TYPES } from "../../../../../helpers/constants";

export const EditPaymentTypes = ({
  userAccess,
  savePayments,
  companyForEdit,
  showEditPayment,
  setShowEditPayments,
}) => {
  const [newPaymentMethod, setNewPaymentMethod] = useState("");
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);

  useEffect(() => {
    if (companyForEdit?.paymentTypes?.length) {
      setPaymentTypesToShow([...companyForEdit?.paymentTypes]);
    } else {
      setPaymentTypesToShow([...PAYMENT_TYPES]);
    }
  }, [companyForEdit]);

  const addPaymentHandler = () => {
    if (newPaymentMethod) {
      setPaymentTypesToShow([
        ...paymentTypesToShow,
        { name: newPaymentMethod, default: false, active: false },
      ]);
      setNewPaymentMethod("");
    } else {
      toast.error("Enter Payment Channel Name");
    }
  };

  const removePaymentHandler = (i) => {
    let data = paymentTypesToShow.filter((item) => {
      return item.name !== paymentTypesToShow[i].name;
    });
    setPaymentTypesToShow(data);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={companyForEdit}
        onSubmit={(values) => {
          savePayments({ _id: values._id, paymentTypes: paymentTypesToShow });
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal
              size="md"
              className="modal-drawer"
              show={showEditPayment}
              onHide={() => {}}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <button
                  type="button"
                  className="btn btn-close"
                  onClick={() => setShowEditPayments(false)}
                >
                  <img src={IconClose} alt="loading" />
                </button>
                <Modal.Title>
                  {i18n("Company.ManagePaymentChannel")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div>
                    <b className="ml-5">{i18n("global.PaymentChannels")}</b>
                    {paymentTypesToShow?.map((item, i) => {
                      return (
                        <div key={i}>
                          <div className="form-group d-flex mt-5">
                            <img
                              src={IconCross}
                              alt="loading"
                              className="mr-3 cursor-pointer"
                              onClick={() => {
                                item.name === "Cash"
                                  ? toast.error(
                                      "You can't delete Cash payment method"
                                    )
                                  : userAccess?.deleteData
                                  ? removePaymentHandler(i)
                                  : toast.error("Access Denied");
                              }}
                            />
                            <Field
                              readOnly
                              disabled
                              name="paymentType"
                              component={Input}
                              value={item.name}
                              placeholder="Payment Method"
                              withFeedbackLabel={false}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div className="form-group d-flex mt-15">
                      <span
                        className="fas fa-plus cursor-pointer mt-2 mr-3"
                        style={{
                          color: "#2D9CDB",
                        }}
                        onClick={() =>
                          userAccess?.createData
                            ? addPaymentHandler()
                            : toast.error("Access Denied")
                        }
                      />
                      <Field
                        name="paymentType"
                        component={Input}
                        placeholder="Enter new payment channel name"
                        withFeedbackLabel={false}
                        value={newPaymentMethod}
                        onChange={(e) => setNewPaymentMethod(e.target.value)}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-elevate"
                  onClick={() => setShowEditPayments(false)}
                >
                  {i18n("Company.Cancel")}
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                    setShowEditPayments(false);
                  }}
                  className="btn btn-primary btn-elevate ml-5"
                >
                  {i18n("Company.Save")}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};
