import React from "react";

const PageNavigator = (props) => {
  const {
    page,
    limit,
    handlePageChange,
    handleLimitChange,
    analyticsTableData,
    data,
  } = props;

  return (
    <div className="page-navigation">
      <div className="main-container">
        <div className="btn-section">
          <button
            className="btn-left"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            {"<"}
          </button>
          <button
            className="btn-right"
            onClick={() => handlePageChange(page + 1)}
            disabled={data?.length < limit}
          >
            {">"}
          </button>
        </div>
        <span>Page</span>
        <span className="page-number">{page}</span>
        <span>Rows per page:</span>
        <select
          className="limit-select"
          value={limit}
          onChange={(e) => handleLimitChange(Number(e.target.value))}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
};

export default PageNavigator;
