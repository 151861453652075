import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { i18n } from "../../../pages/private/languageSelector";
import { Storage } from "react-jhipster";
import { FATORATAY_AUTH } from "../../../../../src/helpers/constants";
import { useHistory } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

function Login(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n("Validation.WrongEmailFormat"))
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.EmailIsRequired")),
    password: Yup.string()
      .min(8, i18n("Validation.Minimum8Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.RequiredField")),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { token } }) => {
            disableLoading();
            if (values.rememberMe) {
              Storage.local.set(FATORATAY_AUTH, token);
            } else {
              Storage.session.set(FATORATAY_AUTH, token);
            }
            props.login(token, values.rememberMe);
            history.push("/");
          })
          .catch((error) => {
            disableLoading();
            setSubmitting(false);
            setStatus(error?.response?.data?.message);
          });
      }, 1000);
    },
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };
  return (
    <div id="kt_login_signin_form">
      <div className="form-title">
        <h1>{i18n("login.LoginToFatoraty")}</h1>
        <p>{i18n("login.EnterYourUserNameAndPassword")}</p>
      </div>

      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}
        <div className="form-group with-icon">
          <span>
            <i className="flaticon2-black-back-closed-envelope-shape"></i>
          </span>
          <field>
            <input
              placeholder={i18n("global.Email")}
              type="email"
              className="form-control"
              name="email"
              {...formik.getFieldProps("email")}
            />
            <label>{i18n("global.Email")}</label>
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </field>
        </div>
        <div className="form-group with-icon">
          <span>
            <i className="fa fa-lock"></i>
          </span>
          <field>
            <input
              placeholder={i18n("global.Password")}
              type={passwordShow ? "text" : "password"}
              className="form-control"
              name="password"
              {...formik.getFieldProps("password")}
            />
            <label>{i18n("global.Password")}</label>
            <span
              onClick={passwordShowHandler}
              style={{
                float: " right",
                marginTop: "-30px",
                position: "relative",
                zIndex: "1",
                cursor: "pointer",
                width: "auto",
              }}>
              <i className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"}></i>
            </span>
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </field>
        </div>

        <div
          className="d-flex flex-wrap justify-content-between align-items-center"
          style={{ paddingLeft: "45px", marginBottom: "5rem" }}>
          <div className="mr-auto">
            <label className="checkbox mb-3">
              <input
                type="checkbox"
                name="rememberMe"
                className="mr-2"
                {...formik.getFieldProps("rememberMe")}
                {...(formik.values.rememberMe ? { checked: true } : {})}
              />
              <span className="mr-2"></span>
              {i18n("login.RememberMe")}
            </label>
            <Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary mr-2" id="kt_login_forgot">
              {i18n("login.ForgotPassword")}
            </Link>
          </div>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 f-button`}
            style={{ width: "auto" }}>
            <span>{i18n("login.SignIn")}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <div className="d-block w-100 mt-3 text-center">
          <span className="font-weight-bold text-dark-50">{i18n("login.DontHaveAnAccountYet")}</span>
          <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
            {i18n("login.RegisterNow")}!
          </Link>
        </div>
        <div className="d-block w-100 text-center">
          <Link to="/en/privacy-policy" className="text-dark-50 text-hover-primary mr-2" id="kt_login_forgot">
              {i18n("login.PrivacyPolicy")}
          </Link>
        </div>
      </form>
    </div>
  );
}

export default connect(null, auth.actions)(Login);
