import React, { useEffect, useRef } from 'react';
import "./style.scss";

const SidebarDialog = ({ columns, visibleColumns, onClose, handleColumnVisibilityChange, isSidebarOpen }) => {
  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div ref={sidebarRef} className={`sidebar-dialog ${isSidebarOpen ? "open" : ""}`}>
      <div className="sidebar-content">
        <div className="columns-list">
          {columns.map((column, index) => (
            <div key={column} className="column-item d-flex align-items-center">
              <input
                className='d-flex align-items-center'
                type="checkbox"
                checked={visibleColumns.includes(column)}
                onChange={() => index !== 0 && handleColumnVisibilityChange(column)} // Disable change for first column
                disabled={index === 0} // Disable checkbox for the first column
              />
              <label className='mt-2 ml-2'>{column}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarDialog;
